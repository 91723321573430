@import "~sky-toolkit-core/tools";

.login-form {
  &__wrapper {
    margin-top: $global-spacing-unit;
    margin-bottom: $global-spacing-unit-small;
  }
  &__input {
    text-align: left;
    margin-bottom: $global-spacing-unit;
    margin-top: $global-spacing-unit-small;
    .c-form-input {
      height: 40px;
      margin-bottom: 0;
    }
    &-password {
      p {
        margin-bottom: -5px;
      }
    }
    .pin-component-secrets .input-container {
      max-height: 40px;
      width: 25%;
      input {
        padding-right: 0px;
        padding-left: 0px;
      }
    }
  }
  &__keep-login {
    min-height: $global-spacing-unit-small;
    &__checkbox {
      text-align: left;
      &.c-form-checkbox {
        margin-bottom: $global-spacing-unit;
      }
    }
  }

  &__btn-login {
    width: 190px;
  }

  @include mq($from: medium) {
    &__wrapper {
      margin-top: 40px;
      box-shadow: $global-shadow;
      padding: $global-spacing-unit $global-spacing-unit;
    }
  }
}

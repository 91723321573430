// All styles which will be used globally
html,
body {
  height: 100%;
  overflow: auto;
  font-family: "Sky Text", Arial, Helvetica, sans-serif;

  @include gradient-background(page, horizontal);
}

#root {
  height: calc(100% - 60px);
}

// Devider
.divider {
  border-bottom-style: solid;
  border-width: $global-border-width;
  border-color: color(grey-10);
}

// Inputs
// Chrome
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  background-color: color(white);
}

// Firefox
input {
  filter: none;
}

@import "~sky-toolkit-core/tools";
@import "~@sky-uk/toolkit-react/lib/Button";
@import "~sky-toolkit-ui/components/typography";
@import "~sky-toolkit-ui/components/buttons";
@import "~sky-toolkit-ui/components/shine";
@import "~sky-toolkit-ui/components/hero";

@import "styles/colors.scss";

.modal {
  z-index: 500;

  &--header {
    text-align: right;
    width: 100%;

    &--icon {
      cursor: pointer;
      fill: white;
      height: 30px;
      border-radius: 1em;
      width: 30px;
      position: fixed;
      top: 0;
      right: 0;
      z-index: 999;
      margin: 10px 30px 0 0;

      @include mq($until: medium) {
        top: 0;
        margin: 0;
      }
    }
  }

  & .c-carousel__mask {
    overflow-x: hidden;
  }

  @include mq($until: medium) {
    overflow-x: hidden;

    & .c-carousel__mask {
      overflow-x: scroll;
    }
  }
}

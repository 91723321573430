.backDrops {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  overflow-y: scroll;
  padding: 5% 0;

  &.white {
    background-color: rgba(255, 255, 255, 0.4);
  }
  &.black {
    background-color: rgba(0, 0, 0, 0.4);
  }
}

@import "~sky-toolkit-core/tools";

span.c-control-message.is-error {
  display: inline-block;

  a {
    color: color(error);
    text-decoration: underline;
  }
}

@import "~sky-toolkit-core/tools";

footer {
  border-top: 1px solid color(grey-10);
  height: fit-content;
  margin-top: $global-spacing-unit-large;

  .footer {
    padding: $global-spacing-unit;

    &__links {
      list-style-type: none;
      margin-bottom: $global-spacing-unit;
      margin-left: 0;
      text-align: left;

      li {
        cursor: pointer;
        display: inline-block;
        line-height: $global-spacing-unit-large;
        margin-right: $global-spacing-unit;
        text-align: left;

        a {
          color: color(text);

          &::after {
            background-image: url("../../assets/icons/arrow-external.svg");
            background-size: contain;
            position: relative;
            top: 4px;
            left: -3px;
          }
        }
      }
    }

    &__disclaimer {
      color: color(grey-40);
      font-size: 12px;
      margin-bottom: 0px;
      text-align: left;

      @include mq($from: large) {
        margin-bottom: 0px;
      }
    }
  }
}

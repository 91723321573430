// Imports
@import "styles/colors.scss";
@import "~sky-toolkit-core/tools";

// Settings
$borderColor: color(grey-20);
$borderMargin: $global-spacing-unit-small * 1.5;

// Styles
.pin-component {
  display: block;
  margin-bottom: $global-spacing-unit-small;
  margin-top: $global-spacing-unit-small;

  &.is-disabled {
    .pin-component-title {
      color: $borderColor;
    }
  }

  &-title {
    text-align: left;
    margin-bottom: $global-spacing-unit-small;
  }

  &-secrets {
    display: flex;
    margin-bottom: $global-spacing-unit-small;
    border-radius: 6px;

    .input-container {
      position: relative;

      flex: 1;
      display: flex;

      &:after {
        position: absolute;
        top: $borderMargin;
        bottom: $borderMargin;
        right: 0px;
        display: block;
        width: 1px;
        background: #808080;
        background-color: $borderColor;
        content: "";
      }

      &:first-child {
        input {
          border-radius: $global-border-radius 0 0 $global-border-radius;
          border-left: 1px solid $borderColor;
        }
      }

      &:last-child {
        input {
          border-radius: 0 $global-border-radius $global-border-radius 0;
          border-right: 1px solid $borderColor;
        }

        &:after {
          display: none;
        }
      }

      input {
        border-top: 1px solid $borderColor;
        border-right: 0px;
        border-bottom: 1px solid $borderColor;
        border-left: 0px;
        text-align: center;
        font-size: 26px;
        flex: 1;

        &:focus {
          outline: none;
        }

        @include mq($until: medium) {
          padding: 0;
          flex: 1;
        }

        &.input-disabled {
          flex: 1;
        }
      }
    }
  }

  &-bubbles {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: $global-spacing-unit;
    max-height: 100px;
    overflow: hidden;
    position: relative;
    transition: max-height 0.3s;

    &.shown {
      max-height: 100px;
    }
  }
}

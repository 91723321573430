@import "sky-toolkit-core/tools";

$simple-masthead-header-height-small: 55px;

$max-mobile-device-with: 320px;

$max-tablet-device-width: 980px;

.main-container {
  background-color: color(#ffffff);
  border-bottom: 1px solid color(grey-10);

  @include mq($until: large) {
    padding: 15px 0 $global-spacing-unit-small;
    width: 100%;
    left: 0;
    z-index: 2;
  }
}

.header {
  display: flex;

  @include mq($from: large) {
    align-items: baseline;
  }

  &__burger-menu-wrapper {
    cursor: pointer;
    align-self: center;
    align-items: baseline;
    overflow: hidden;
    height: auto;
    position: relative;
    left: -5px;
    top: 2px;
  }

  &__logo {
    width: 56px;
    margin: $global-spacing-unit-small;
  }

  &__main-menu {
    .main-menu-element {
      padding: $global-spacing-unit;
      display: inline-block;
    }

    .main-menu-link {
      font-size: 22px;
      line-height: 1.36;
      color: color(text);
      text-transform: capitalize;

      &.external-link {
        padding: $global-spacing-unit;
      }

      &.active,
      &.isActive {
        font-weight: 700;
      }
    }

    .sub-menu-list {
      padding: 0 $global-spacing-unit $global-spacing-unit $global-spacing-unit;
    }

    .sub-menu-link {
      margin-right: $global-spacing-unit;
      color: inherit;
      font-size: 18px;
      line-height: 1.4;
      text-transform: capitalize;

      &.active {
        font-weight: 700;
      }
    }
  }

  @media screen and (max-width: $max-tablet-device-width) {
    &__logo {
      margin: 0;
    }
  }

  // From  mobile  until  max tablet device
  @media screen and (max-width: $max-tablet-device-width) and (min-width: $max-mobile-device-with) {
    &__logo-wrapper {
      width: 100%;
    }

    &__logo {
      width: 55.53px;
    }
  }

  &__logo-wrapper {
    display: flex;
    justify-content: center;
  }

  &__login {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    top: $global-spacing-unit-tiny;

    .search {
      padding-top: 10px;
      padding-right: 17px;
    }

    a {
      display: flex;
      align-items: center;
      color: color(text);
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }

    svg {
      height: 26px;
      width: 26px;
    }

    .guest {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
}

.close-icon-burger {
  color: color(brand);
  cursor: pointer;
  align-self: center;
  align-items: baseline;
  overflow: hidden;
}

@import "~sky-toolkit-core/tools";

$icon-width: 18px;
$icon-height: 18px;
$icon-display: inline-block;
$icon-margin-right: $global-spacing-unit;

.error-message {
  &-white-bg {
    margin: $global-spacing-unit 0;
    padding: $global-spacing-unit-small;
    color: color(error);
    font-weight: 900;

    .icon {
      height: $icon-height;
      width: $icon-width;
      min-width: $icon-width;
      margin-right: $global-spacing-unit-small;
      display: $icon-display;

      &-alert {
        fill: color(error);
        position: relative;
        top: 3px;
      }

      &-white {
        fill: color(white);
      }
    }

    .message {
      text-align: center;

      span {
        a {
          color: color(error);
          text-decoration: underline;
        }
      }
    }
  }

  &-red-bg {
    margin: $global-spacing-unit 0;
    padding: $global-spacing-unit-small;
    text-align: left;
    border-radius: 4px;
    flex-flow: column;
    background-color: color(error);
    color: color(white);

    a {
      color: color(white);
      text-decoration: underline;
    }

    .message {
      display: flex;
      justify-content: center;
      align-items: flex-start;

      @include mq($until: large) {
        justify-content: flex-start;
      }
    }

    .link-message {
      margin: 0 0 0 28px;
    }

    .icon {
      width: $icon-width;
      min-height: $icon-height;
      min-width: $icon-width;
      margin: 4px $global-spacing-unit-small 0 0;
      display: $icon-display;

      &-alert {
        fill: color(white);
      }

      &-white {
        fill: color(error);
      }
    }
  }
}

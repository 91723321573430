.o-container {
  width: 100%;
}

.o-layout__item {
  &.p-0 {
    padding-left: 0;

    &--desktop-20 {
      @include mq($from: medium) {
        padding-left: 20px;
      }
    }
  }
}

.content {
  flex: 1 1 100%;
  flex-direction: column;
  box-sizing: border-box;
}

@import "~sky-toolkit-core/tools";
@import "~sky-toolkit-ui/components/typography";
@import "~sky-toolkit-ui/components/accordion";
.data-privacy {
  .underline {
    text-decoration: underline;
  }
  .c-link-external {
    &::after {
      background: transparent url("../../assets/icons/arrow-external.svg")
        no-repeat 0 0;
      background-size: contain;
      position: relative;
      top: 4px;
      left: -3px;
    }
  }
}

.data-privacy .o-layout {
  margin-left: $global-spacing-unit;
  margin-right: $global-spacing-unit;
}

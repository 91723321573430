// Imports
@import "~sky-toolkit-core/tools";

// Main styles
.main-application {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow: hidden;
  position: relative;

  &__loading {
    margin: $global-spacing-unit-large 0;
  }
}

@import "styles/colors.scss";
@import "~sky-toolkit-core/tools";

.block-message {
  margin: 64px 0;

  @include mq($from: medium) {
    margin: 61px 0;
  }

  &-title {
    margin: $global-spacing-unit 0 $global-spacing-unit-small 0;
  }

  .alert-icon {
    width: 61px;
  }

  .icon-checkmark {
    height: 55px;
    width: 55px;
    margin-bottom: $global-spacing-unit-small;
  }

  .c-btn {
    min-width: 193px;
  }
}

@import "sky-toolkit-core/tools";

.mobile-main-navigation {
  @include mq($until: large) {
    margin: $global-spacing-unit-small 0 0 0;
    padding: $global-spacing-unit-large 0 0;
    border-top: 1px solid #eaeaea;
    position: fixed;
    width: 100%;
    background: #fff;
    z-index: 9;
    height: 100vh;
  }

  &__main-list {
    list-style-type: none;
    margin: $global-spacing-unit-small 0 0 0;
  }

  &__main-link-container {
    display: flex;
  }

  .main-item {
    border-bottom: 1px solid color(grey-10);
    padding: $global-spacing-unit;

    a {
      color: color(text);
    }
    &:last-of-type {
      border-bottom: none;
    }
  }

  .main-link {
    color: color(text);
    font-size: 25px;
    line-height: 1.24;
    width: 100%;
    display: block;
    position: relative;
    text-transform: capitalize;

    &.active {
      font-weight: 700;
    }

    &:hover {
      text-decoration: none;
    }

    svg {
      position: absolute;
      right: 0;
      width: 30px;
    }
  }

  .sub-menu-item {
    border-top: 1px solid color(grey-10);
    margin: $global-spacing-unit -20px 0;
    padding: $global-spacing-unit $global-spacing-unit 0
      $global-spacing-unit-large;
    text-transform: capitalize;
    font-weight: lighter;
  }

  .sub-menu-link {
    color: inherit;
    display: block;
    font-size: 18px;
    line-height: 1.4;
    margin-bottom: 30px;
    position: relative;
    text-transform: capitalize;

    &:last-of-type {
      margin-bottom: 0;
    }

    &.active {
      font-weight: 700;
    }

    svg {
      position: absolute;
      right: 0;
      width: 30px;
    }
  }
}

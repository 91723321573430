@import "~sky-toolkit-core/tools";

.bubble {
  background-color: rgba(color(text), 0.5);
  border-radius: 20px;
  color: color(white);
  display: inline-block;
  line-height: 0;
  margin-bottom: $global-spacing-unit-tiny;
  margin-right: $global-spacing-unit-tiny;
  padding: 3px $global-spacing-unit-small;
  vertical-align: middle;

  svg {
    &.icon-tick,
    &.icon-cross {
      width: 10px;
      height: auto;
      margin-right: 5px;
    }
  }

  &.correct {
    background-color: color(success);
  }

  &.error {
    background-color: color(error);
  }
}

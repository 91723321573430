@font-face {
  font-family: "Sky Text";
  src: url("../assets/fonts/sky-regular.woff") format("woff");
  src: url("../assets/fonts/sky-regular.eot");
  src: url("../assets/fonts/sky-regular.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/sky-regular.woff2") format("woff2"),
    url("../assets/fonts/sky-regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Sky Text";
  src: url("../assets/fonts/sky-medium.woff") format("woff");
  src: url("../assets/fonts/sky-medium.eot");
  src: url("../assets/fonts/sky-medium.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/sky-medium.woff2") format("woff2"),
    url("../assets/fonts/sky-medium.ttf") format("truetype");
  font-weight: bold;
}

@import "~sky-toolkit-core/tools";
@import "styles/colors.scss";

@mixin custom-scroll-bar() {
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
    margin: 10px 0 10px;
    border-radius: 15px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: color(grey-10);
    border-radius: 10px;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

@mixin custom-scroll-bar-desktop() {
  ::-webkit-scrollbar {
    width: 18px;
  }

  ::-webkit-scrollbar-track {
    background: color(white);
    margin: 0;
    border-radius: 0px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 40px;
    background-color: color(grey-20);
    border: 3px solid color(white);
  }

  ::-webkit-scrollbar-corner {
    background-color: color(white);
  }
}

.scroll {
  @include custom-scroll-bar;
  @include mq($from: medium) {
    @include custom-scroll-bar-desktop;
  }
}

.watershed-overlay {
  z-index: 21;
  position: fixed;
  top: 0;
  left: 0;
  background-color: color(white);
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
  width: 100%;

  .o-container {
    overflow-y: scroll;
    height: 90vh;
    position: relative;
    margin: 0 0 40px 0;
  }

  &--header {
    padding: $global-spacing-unit-small 0;
    border-bottom: 1px solid color(grey-10);
    text-align: right;
    padding-right: $global-spacing-unit;
    &--icon {
      fill: color(brand);
      height: 26px;
    }
  }

  .success-icon {
    margin-top: $global-spacing-unit;
  }

  &--title {
    margin: $global-spacing-unit 0;
  }

  @include mq($from: medium) {
    background-color: rgba(0, 0, 0, 0.2);
    overflow-y: scroll;
    height: 100vh;

    .o-layout {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      &.align-center {
        margin-top: -55px;
      }

      &.align-top {
        align-items: start;
        justify-content: center;
      }
    }

    .o-container {
      overflow-y: initial;
      height: initial;
      margin: 0 0 40px 0;
    }

    &--container {
      margin: $global-spacing-unit 0;
      background-color: white;
      &.o-layout__item {
        padding-left: 0;
      }
    }

    &--title {
      margin: $global-spacing-unit-small 0 $global-spacing-unit-large 0;
    }

    &--header {
      border-bottom: 1px solid transparent;
    }
  }
}

@import "sky-toolkit-core/tools";

.main-item {
  border-bottom: 1px solid color(grey-10);
  padding: $global-spacing-unit;

  .main-menu-container {
    display: grid;
    grid-template-columns: 1fr 30px;

    .button-icon {
      padding: 0;
      background: transparent;
      border: none;
      &:focus {
        outline: none;
      }
    }
  }

  .sub-menu-container {
    .sub-menu-list {
      margin: 0;
      list-style-type: none;
    }

    .sub-menu-item {
      display: flex;

      &:not(:first-of-type) {
        border: none;
      }
    }

    .sub-menu-link {
      color: color(text);
      font-size: $global-spacing-unit;
      line-height: 1.24;
      width: 100%;
      display: block;
      position: relative;
      text-transform: capitalize;

      svg {
        width: 25px;
      }
    }
  }
}

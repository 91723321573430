$colors: (
  cyan-blue: #e6f1f9,
  violet: #5a15b9,
  purple-dark: #5d1fb3,
  violet-dark: #6a2cfc,
  seance: #801eae,
  electric-violet: #7d2bf0,
  purple-light: #a827d5,
  persian-rose: #f822a2,
  cerise: #f922a2,
  rose: #ff1189,
  ruby: #ca1461,
  maroon: #c51e48,
  matisse: #1a77a4,
  strong-blue: #159cb9,
  brand: #0073c5,
  brandLight: #02468a,
  highlight: #73add8,
  java: #1bc1e4,
  blue-violet: #5f25fa,
  blaze-orange: #ff5f07,
  error: #ff2744,
  success: #1cb334,
  palma: #15ac2d,
  green-leaf: #296706,
  grey-10: #eaeaea,
  grey-20: #c0c0c0,
  grey-30: #9f9f9f,
  grey-40: #7f7f7f,
  grey-50: #222,
  text: #4a4a4a,
  black: #000,
  white: #fff,
  offer: #f15a22
) !default;

@function color($key) {
  $map: map-get($colors, $key);
  @return $map;
}

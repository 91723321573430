@import "styles/colors.scss";
@import "~sky-toolkit-core/tools";

.login-form {
  &__register {
    text-align: left;
    margin-top: $global-spacing-unit-large;
    p {
      margin-bottom: 8px;
    }
    .numberLink {
      display: flex;
      cursor: pointer;
      color: color(brand);
      align-items: flex-start;
      .infoIcon {
        use {
          fill: color(brand);
        }
        width: $global-spacing-unit;
        margin: 0 8px 0 0;
        @include mq($from: medium) {
          margin: 2px 8px 0 0;
        }
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__link {
    color: color(brand);
    text-decoration: none;
    cursor: pointer;
    margin: $global-spacing-unit 0 0 0;
    &:hover {
      text-decoration: underline;
    }
  }
}
